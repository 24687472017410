<template>
  <CContainer class="d-flex align-items-center min-vh-100 col-md-12">

    <div class="langtoggle">
    <CDropdown :show.sync="showDropdown">
      <template #toggler>
        <CHeaderNavLink>
          <div class="c-avatar" style="width: 30px;height: 30px;">
            <img alt="Thai" src="/img/languages/th_icon.png" class="c-avatar-img " v-if="$i18n.locale === 'th'" />
            <img alt="Eng" src="/img/languages/en_icon.png" class="c-avatar-img " v-else />
          </div>
        </CHeaderNavLink>
      </template>
      <label>
        <CDropdownItem v-if="$i18n.locale === 'en'" @click="changeLocale('th')">
          <div class="c-avatar" style="width: 30px;height: 30px;">
            <img alt="Thai" src="/img/languages/th_icon.png" class="c-avatar-img " @click="changeLocale('th')" />
          </div>
          <span style="margin-left: 20px;"> ภาษาไทย </span>
        </CDropdownItem>
      </label>
      <label>
        <CDropdownItem v-if="$i18n.locale === 'th'" @click="changeLocale('en')">
          <div class="c-avatar" style="width: 30px;height: 30px;">
            <img alt="Eng" src="/img/languages/en_icon.png" class="c-avatar-img " @click="changeLocale('en')" />
          </div>
          <span style="margin-left: 20px;"> English </span>
        </CDropdownItem>
      </label>
    </CDropdown>
  </div>
    <CCol class="ml-1 mr-1 ">
      <CRow class="justify-content-center">
        <CCard color="white" text-color="dark" class="text-center col-lg-4 col-md-6 shadow-sm" style="border-radius:0px">
          <CCardBody>
            <CAlert v-if="alert != ''" color="danger">{{ alert }}</CAlert>
            <CRow>
              <CCol md="12">
                <h1 class="font-weight-normal" style="font-size:25px;">
                  <img src="/silom-pos.png" style="width:13%" class="img-fluid" alt="" />
                  <span class="text-dark mt-3 font-weight-normal">
                    Silom
                  </span>
                  <span class="text-success mt-3 font-weight-normal">
                    CRM
                  </span>
                </h1>
                <br />
                <br />
                <h3 class="text-left font-weight-normal text-dark mb-3">
                  {{ $t('login') }}
                </h3>

                <CForm @keyup.enter="login()">
                  <CInput v-model="email" type="email" placeholder="อีเมล" autocomplete="username email">
                  </CInput>
                  <CInput v-model="password" type="password" placeholder="รหัสผ่าน" autocomplete="curent-password">
                  </CInput>
                  <router-link to="/forgotpassword" style="text-decoration: none;">
                    <p class="text-left text-info">{{ $t('forgetPassword') }}</p>
                  </router-link>
                  <CRow>
                    <CCol col="12" v-if="loadingButton === true">
                      <CButton @click="login" block color="success" class="px-4">
                        {{ $t('login') }}
                      </CButton>
                    </CCol>
                    <CCol col="12" v-else-if="loadingButton === false">
                      <CButton block color="success" disabled>
                        <CSpinner color="white" size="sm" />
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CRow>
    </CCol>
  </CContainer>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data() {
    return {
      email: '',
      password: '',
      alert: '',
      loadingButton: true,
      showDropdown: false,
    }
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale
      this.showDropdown = false
    },
    async login() {
      this.loadingButton = false
      try {
        const user = await firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        const idToken = user.user.ya
        const uid = user.user.uid
        localStorage.setItem('idToken', idToken)
        localStorage.setItem('uid', uid)

        await this.$store.dispatch('createToken')
        
        this.loadingButton = true
      } catch (error) {
        this.alert = err.message
        this.loadingButton = true
      }
  },
  googleSignIn() {
    const provider = new firebase.auth.GoogleAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((user) => {
        const idToken = user.user.ya
        const uid = user.user.uid
        localStorage.setItem('idToken', idToken)
        localStorage.setItem('uid', uid)
        this.$store.dispatch('createToken')
      })
      .catch((err) => {
        this.alert = err.message
        alert('Oops.' + err.message)
      })
  },
  facebookSignIn() {
    const provider = new firebase.auth.FacebookAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((fbuser) => {
        const idToken = fbuser.user.ya
        const uid = fbuser.user.uid
        localStorage.setItem('idToken', idToken)
        localStorage.setItem('uid', uid)
        this.$store.dispatch('createToken')
      })
      .catch((err) => {
        this.alert = err.message
        alert('Oops.' + err.message)
      })
  },
},
}
</script>

<style scoped>
.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  margin: 8px 0px;
}

.hr-sect:before,
.hr-sect:after {
  content: '';
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}


.langtoggle {
  position: absolute;
  top: 0%;
  right: 0%;
  margin: 8px;
}
</style>
